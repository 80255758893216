import React, { ReactNode } from "react";
import "./Centered.css";

type CenteredPropsType = {
  children: ReactNode;
};

export const Centered = (props: CenteredPropsType) => {
  const { children } = props;
  return <div className="centered">{children}</div>;
};
