import "./App.css";
import {Centered} from "./components/_base/centered/Centered";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Centered>
          Teichmeister
        </Centered>
      </header>
    </div>
  );
}

export default App;
